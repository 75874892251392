<!-- =========================================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="Assignment Not Found" :active.sync="errorOccurred">
      <span>Assignment record with id: {{ $route.params.assignmentId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'admin-list-examedge-assignment' }" class="text-inherit underline">All Assignments</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="assignmentData">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <assignment-details-tab class="mt-4" :data="assignmentData" v-on:updateAssignmentData="updateAssignmentData" />
            </div>
          </vs-tab>
          <!---    <vs-tab label="Information" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
          <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
          <!---  <vs-tab label="Social" icon-pack="feather" icon="icon-share-2">
          <div class="tab-text">
          <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AssignmentDetailsTab from './AssignmentDetailsTab.vue';

export default {
  components: {
    AssignmentDetailsTab,
  },
  data() {
    return {
      assignmentData: null,
      errorOccurred: false,

      activeTab: 0,
    };
  },
  methods: {
    loadAssignmentData() {
      this.$http
        .get(`examedge/assignment/${this.$route.params.assignmentId}`)
        .then((response) => {
          if (response.status === 200) {
            this.assignmentData = response.data;
          }
        })
        .catch((error) => {
          this.errorOccurred = true;
        });
    },
    updateAssignmentData(data) {
      const pl = {
        ...data,
        rangeStart: parseInt(data.rangeStart),
        rangeEnd: parseInt(data.rangeEnd),
        questions: parseInt(data.questions),
        timer: parseInt(data.timer),
        minScore: data.minScore === '' ? null : parseInt(data.minScore),
        status: data.status === undefined ? 'active' : data.status,
      };

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update assignment',
          text: 'Please provide a Assignment Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .patch(`examedge/assignment/${this.$route.params.assignmentId}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Updated Assignment Successfully',
              text: `Assignment ${pl.name} has been updated.`,
              color: 'success',
              position: 'top-right',
            });
            return this.loadAssignmentData();
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating this assignment';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to update assignment',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  created() {
    this.loadAssignmentData(this.$route.params.assignmentId);
  },
};
</script>
