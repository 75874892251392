<template>
  <div id="assignment-create-details-tab" @keyup.ctrl.enter="assignment_update_data">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col w-full mb-6">
          <vs-input v-model="dataLocal.id" class="w-full" label-placeholder="Assignment ID" readonly />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-input v-model="dataLocal.name" class="w-full" label-placeholder="Assignment Name" />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-select class="w-full mt-5" placeholder="Assignment Status" v-model="dataLocal.status">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in statusOptions" />
          </vs-select>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <vs-textarea v-model="dataLocal.description" height="100px" label="Assignment Description" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mb-4">
            <p>Quiz Mode</p>
            <vs-radio
              color="white"
              v-model="dataLocal.type"
              :vs-value="`study`"
              vs-name="mode"
              class="p-2 font-bold rounded-lg bg-primary mr-2"
              >Study Mode
            </vs-radio>
            <vs-radio color="white" v-model="dataLocal.type" :vs-value="`test`" vs-name="mode" class="p-2 font-bold rounded-lg bg-primary"
              >Test Mode
            </vs-radio>
          </div>

          <div class="flex justify-items-auto items-center gap-6 popup-input no-number-btn mb-8">
            <div>
              <p>Range Start</p>
              <vs-input-number v-model="dataLocal.rangeStart" type="number" min="1" :max="55" />
            </div>
            <div>
              <p>Range End</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.rangeEnd" type="number" min="1" />
            </div>
            <div>
              <p>Questions</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.questions" type="number" min="1" />
            </div>
            <div>
              <p>Timer</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.timer" type="number" min="0" :max="720" />
            </div>
            <div>
              <p>Min Score</p>
              <vs-input-number hide-spin-buttons v-model="dataLocal.minScore" type="number" min="0" :max="100" />
            </div>
            <div>
              <p class="mb-2">Shuffle</p>
              <vs-switch color="success" v-model="dataLocal.shufle" />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mb-2 mr-2" color="success" type="filled" @click="assignment_update_data">Update Assignment </vs-button>
          <vs-button class="mb-2 ml-2" color="warning" type="border" @click="reassignment_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    assignment_update_data() {
      this.$emit('updateAssignmentData', this.dataLocal);
    },
    reassignment_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style scoped></style>
